
const digest = 'c03f1065323f66b9edf11bbae3a588eb50b3cbdca6eb5c4bce506e1c2443c738';
const css = `._formWrapper_1kd6c_1 {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: repeat(auto-fill, 1fr);
}

._nameRow_1kd6c_7 {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"formWrapper":"_formWrapper_1kd6c_1","nameRow":"_nameRow_1kd6c_7"};
export { css, digest };
  