
const digest = 'dcdd4609503eeedc44bd24e1da33da370b101fcb379cb5471c2db47246d170e5';
const css = `._overlay_1ctld_1 {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  align-items: center;
  background: rgba(247, 249, 250, 0.95);
  display: flex;
  justify-content: center;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  text-align: center;
}

._tall_1ctld_19 {
  align-items: start;
}

._modal_1ctld_23 {
  position: relative;

  background: var(--color-white);
  border: 1px solid var(--color--neutral-20);
  border-radius: 4px;
  box-sizing: border-box;
  /* overflow: hidden; */
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  text-align: left;
  width: 100%;
}

._modal_1ctld_23._x-small_1ctld_36 {
  /* 400px */
  max-width: 25rem;
}

._modal_1ctld_23._small_1ctld_41 {
  /* 440px */
  max-width: 27.5rem;
}

._modal_1ctld_23._medium_1ctld_46 {
  /* 480px */
  max-width: 32rem;
}

/* This should likely be a size? */
._modal_1ctld_23._sample_1ctld_52 {
  /* 1200 */
  max-width: 75rem;
}

._modal_1ctld_23 ._body_1ctld_57 {
  display: flex;
  flex-direction: column;
}

._header_1ctld_62 {
  display: grid;
  grid-template-columns: 1fr 4rem;
  margin-bottom: 2rem;
}

._close_1ctld_68 {
  align-self: flex-start;
  background: none;
  border: none;
  color: var(--color-neutral-10);
  cursor: pointer;
  font-size: 0.75rem;
  justify-self: flex-end;
  letter-spacing: 0.02em;
  line-height: 1rem;
  outline: none;
  text-align: right;
  text-transform: uppercase;
}

._title_1ctld_83 {
  color: var(--color-neutral-40);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1rem;
  text-align: left;
}

._subheading_1ctld_91 {
  color: var(--color-neutral-10);
  font-size: 1rem;
  line-height: 1.25;
  margin: 1.5rem 0 0;
  text-align: left;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"overlay":"_overlay_1ctld_1","tall":"_tall_1ctld_19","modal":"_modal_1ctld_23","x-small":"_x-small_1ctld_36","xSmall":"_x-small_1ctld_36","small":"_small_1ctld_41","medium":"_medium_1ctld_46","sample":"_sample_1ctld_52","body":"_body_1ctld_57","header":"_header_1ctld_62","close":"_close_1ctld_68","title":"_title_1ctld_83","subheading":"_subheading_1ctld_91"};
export { css, digest };
  