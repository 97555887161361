import ajax from "Blocks/utils/ajax";

const AWS = {
  getSignedUrl: (file, callback) => {
    ajax({
      path: "/aws-signed-url",
      method: "GET",
      data: { mime: file.type, filename: file.name },
      success: (response) => {
        const data = JSON.parse(response),
          form = new FormData();
        form.append("key", data.key);
        form.append("AWSAccessKeyId", data.aws_key);
        form.append("Content-Type", file.type);
        form.append("policy", data.aws_policy);
        form.append("signature", data.aws_signature);
        form.append("success_action_status", "201");
        form.append("file", file);

        AWS.uploadToS3(file, form, data.aws_bucket, callback);
      },
    });
  },

  uploadToS3: (file, form, bucket, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://" + bucket + ".s3.amazonaws.com/", true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (window.DOMParser) {
          var parser = new DOMParser();
          var xmlDoc = parser.parseFromString(xhr.responseText, "text/xml");
          // Internet Explorer
        } else {
          var xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
          xmlDoc.async = false;
          xmlDoc.loadXML(txt);
        }

        var urlEncoded = xmlDoc.getElementsByTagName("Location")[0].innerHTML;
        var urlUnescaped = unescape(urlEncoded);

        callback(urlUnescaped);
      }
    };
    xhr.send(form);
  },
};

export default AWS;
