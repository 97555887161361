import { createLayout, styled } from "@plan/core";

const layout = createLayout();

export const Flex = styled("div", {
  ...layout,
  display: "flex",
  variants: {
    ...layout.variants,
    column: {
      true: {
        flexDirection: "column",
      },
    },
  },
});
