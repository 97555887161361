import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import ProjectGroupTitle from "./ProjectGroupTitle";
import ProjectRow from "./ProjectRow";
import { useGlobalState } from "../state/GlobalContext.jsx";
import styles from "./project.module.css";

const GET_PHASES = gql`
  query project($slug: String!) {
    project(slug: $slug) {
      phases {
        id
        name
      }
    }
  }
`;

const GET_TIMESHEET_PROFILE = gql`
  query profile($slug: String!) {
    profile(slug: $slug) {
      id
    }
  }
`;

const GET_TIMESHEET_ENTRY_GROUPS = gql`
  query timesheets($id: ID!) {
    timesheets(id: $id) {
      entryGroups {
        phase {
          id
        }
      }
    }
  }
`;

const GET_SINGLE_ENTRY_GROUP = gql`
  query entryGroup($id: ID!) {
    entryGroup(id: $id) {
      id
      entries {
        id
        expenses {
          id
          onOpenInvoice
        }
      }
    }
  }
`;

function ProjectGroup({
  startOfWeek,
  projectId,
  groupId,
  activityOptions,
  color,
  canEdit,
  blockName,
  activityRequired,
  noteExample,
  notesRequired,
  permissions,
  currentProfile,
  timesheetIsLocked,
}) {
  const { state } = useGlobalState();

  const [group, setGroup] = useState(state.projectGroups.byId[groupId]);
  const { rows } = state;

  useEffect(() => {
    setGroup(state.projectGroups.byId[groupId]);
  }, [state]);

  const projectSlug = state.selectedProjects.byId[projectId].slug;
  const { data: phasesData } = useQuery(GET_PHASES, {
    variables: { slug: projectSlug },
  });

  const { data: profileData } = useQuery(GET_TIMESHEET_PROFILE, {
    variables: { slug: state.timesheet.profileSlug },
  });

  const { loading: timesheetLoading } = useQuery(GET_TIMESHEET_ENTRY_GROUPS, {
    variables: { id: state.timesheet.id },
  });

  const { data: entryGroupData } = useQuery(GET_SINGLE_ENTRY_GROUP, {
    variables: { id: groupId },
  });

  if (
    !group.manuallyAdded &&
    Number(group.allocation) === 0 &&
    !group.hasTime &&
    !group.hasExpenses
  )
    return null;

  // Checks if the entry group phase has expenses attached to open invoices
  const entries = entryGroupData?.entryGroup?.entries || [];

  const hasOpenInvoice = false;

  return (
    <div className={styles.group}>
      <ProjectGroupTitle
        groupId={groupId}
        phasesData={phasesData}
        profileData={profileData}
        hasOpenInvoice={hasOpenInvoice}
        timesheetLoading={timesheetLoading}
        permissions={permissions}
        currentProfile={currentProfile}
        timesheetIsLocked={timesheetIsLocked}
      />
      <div className={styles.activities}>
        {group.entryRows.map((rowId, index) => {
          const zIndex = group.entryRows.length - index + 1;
          const uniqueKey = `${groupId}-${rowId}`;
          const row = rows.byId[rowId];
          const lastRow = index === group.entryRows.length - 1;
          if (!row) return null;
          return (
            <div
              key={uniqueKey}
              className={styles.activity}
              style={{ zIndex, position: "relative" }}
            >
              <ProjectRow
                startOfWeek={startOfWeek}
                canEdit={canEdit}
                color={color}
                rowId={rowId}
                blockName={blockName}
                activityOptions={activityOptions}
                groupId={groupId}
                projectId={projectId}
                lastRow={lastRow}
                activityRequired={activityRequired}
                noteExample={noteExample}
                notesRequired={notesRequired}
                hasOpenInvoice={hasOpenInvoice}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProjectGroup;
