/* global window RAILS_ENV */
function tracker({ event, ...data }) {
  if (RAILS_ENV === "production" && window.analytics) {
    window.analytics.track(event, data);
  }

  if (RAILS_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log(`[Tracker]: ${event}`, data);
  }
}

const resourceEvents = {
  trackUpgradeLander: ({ pathString }) =>
    tracker({
      event: "Upgrade Lander - Visited",
      path: pathString,
    }),
  trackUpgradeLanderCtaClick: ({ pathString, buttonText }) =>
    tracker({
      event: "Upgrade Lander CTA - Clicked",
      path: pathString,
      button_text: buttonText,
    }),
  trackResourceAllocationCreate: ({ label }) =>
    tracker({
      event: "Allocations created",
      feature: "Resource",
      label,
    }),
  trackResourceAllocationDelete: ({ projectId }) =>
    tracker({
      event: "Allocation deleted",
      feature: "Resource",
      Project: projectId,
    }),
  trackResourceAllocationSelect: () =>
    tracker({
      event: "Allocation selected",
      feature: "Resource",
    }),
  trackResourceGridViewToggleEdit: ({ value }) =>
    tracker({
      event: "Grid view toggle edited",
      feature: "Resource",
      value,
    }),
  trackResourceFinancialToggleEdit: ({ value }) =>
    tracker({
      event: "Financial toggle edited",
      feature: "Resource",
      value: value ? "Off" : "On",
    }),
  trackResourceFilterApplied: ({ filter, value }) =>
    tracker({
      event: "Filter Applied",
      feature: "Resource",
      filter,
      value,
    }),
  trackResourceFilterCleared: ({ filter }) =>
    tracker({
      event: "Filter Cleared",
      feature: "Resource",
      filter,
    }),
  trackResourceDateChanged: ({ prevDate, newDate }) =>
    tracker({
      event: "Date Changed",
      feature: "Resource",
      prevDate,
      newDate,
    }),
  trackResourceSort: ({ prevSort, newSort }) =>
    tracker({
      event: "Resources Sorted",
      feature: "Resource",
      prevSort,
      newSort,
    }),
  trackUpdateScheduleClicked: () =>
    tracker({
      event: "Clicked Update Schedule",
      feature: "Resource",
    }),
  trackResourceClearAllocations: () =>
    tracker({
      event: "Cleared Suggested Allocations",
      feature: "Resource",
    }),
};

const projectsEvents = {
  trackProjectTemplateCreate: () =>
    tracker({
      event: "Project template created",
      feature: "Projects",
    }),
  trackProjectTemplateDelete: () =>
    tracker({
      event: "Project template deleted",
      feature: "Projects",
    }),
  trackProjectTotalFeeEdit: () =>
    tracker({
      event: "Project total fee edited",
      feature: "Projects",
    }),
  trackProjectBudgetLockEdit: ({ value }) =>
    tracker({
      event: "Project budget lock edited",
      feature: "Projects",
      value: !value ? "Locked" : "Unlocked",
    }),
  trackProjectPhaseTemplateCreate: () =>
    tracker({
      event: "Phase template created",
      feature: "Projects",
    }),
  trackProjectRoleAdd: (projectId) =>
    tracker({
      event: "Role added",
      feature: "Projects",
      Project: projectId,
    }),
  trackProjectRoleTemplateCreate: () =>
    tracker({
      event: "Role template created",
      feature: "Projects",
    }),
  trackTimeLogExport: ({ group, filter }) => {
    const names = ["profiles", "phases", "activities"];
    const filteredBy = Object.keys(filter).reduce((filters, key) => {
      if (!names.includes(key) || !filter[key].length) return filters;
      return [...filters, key];
    }, []);
    tracker({
      event: "Time log exported",
      feature: "Projects",
      groupedBy: group,
      filteredBy,
    });
  },
  trackProjectProfileInvitationSubmit: () =>
    tracker({
      event: "Profile invitation submitted",
      feature: "Projects",
    }),
};

const timesheetsEvents = {
  trackTimesheetOverheadSelect: () =>
    tracker({
      event: "Timesheet overhead selected",
      feature: "Timesheets",
    }),
  trackTimesheetSubmit: () =>
    tracker({
      event: "Timesheet submitted",
      feature: "Timesheets",
    }),
  trackTimesheetSelect: () =>
    tracker({
      event: "Timesheet selected",
      feature: "Timesheets",
    }),
  trackTimesheetOrganizationSelect: () =>
    tracker({
      event: "Organization timesheet selected",
      feature: "Timesheets",
    }),
  trackTimesheetEntryRowDeleted: ({ rowId }) =>
    tracker({
      event: "Timesheet entry row deleted",
      feature: "Timesheets",
      rowId,
    }),
  trackTimesheetExportedForAjera: () =>
    tracker({
      event: "Timesheet in Ajera format exported",
      feature: "Timesheets",
    }),
};

const reportsEvents = {
  trackTeamWorkDistributionUpgrade: ({ componentTitle, buttonText }) =>
    tracker({
      event: "Upgrade Component CTA - Clicked",
      path: "/",
      component_title: componentTitle,
      button_text: buttonText,
    }),
  trackReportSelect: (type) =>
    tracker({
      event: "Report type selected",
      feature: "Reports",
      type,
    }),
  downloadedPayrollReport: ({ beginDate, endDate }) =>
    tracker({
      event: "Payroll Report Downloaded",
      feature: "Reports",
      beginDate,
      endDate,
    }),
};

const teamEvents = {
  trackAddMemberButtonClick: () =>
    tracker({
      event: "Add member button clicked",
      feature: "Team",
    }),
};

const settingsEvents = {
  trackSettingsOverheadMultiplierEdited: ({ value }) =>
    tracker({
      event: "Overhead multiplier edited",
      feature: "Settings",
      value,
    }),
  trackSettingsCategoryCreate: () =>
    tracker({
      event: "Category created",
      feature: "Settings",
    }),
  trackSettingsCategoryDelete: () =>
    tracker({
      event: "Category deleted",
      feature: "Settings",
    }),
  trackSettingsPhaseCreate: () =>
    tracker({
      event: "Phase template created",
      feature: "Settings",
    }),
  trackSettingsPhaseDelete: () =>
    tracker({
      event: "Phase template deleted",
      feature: "Settings",
    }),
  trackSettingsRoleTemplateCreate: () =>
    tracker({
      event: "Role template created",
      feature: "Settings",
    }),
  trackSettingsRoleTemplateDelete: () =>
    tracker({
      event: "Role template deleted",
      feature: "Settings",
    }),
  trackSettingsRoleTemplateArchive: () =>
    tracker({
      event: "Role template archived",
      feature: "Settings",
    }),
  trackSettingsRoleTemplateUnArchive: () =>
    tracker({
      event: "Role template unarchived",
      feature: "Settings",
    }),
  trackSettingsTimesheetsOverheadCreate: ({ name }) =>
    tracker({
      event: "Timesheet overhead created",
      feature: "Settings",
      name,
    }),
  trackSettingsTimesheetsOverheadDelete: () =>
    tracker({
      event: "Timesheet overhead deleted",
      feature: "Settings",
    }),
  trackSettingsTimesheetsActivityCreate: ({ value }) =>
    tracker({
      event: "Timesheet activity created",
      feature: "Settings",
      value,
    }),
  trackSettingsTimesheetsActivityDelete: () =>
    tracker({
      event: "Timesheet activity deleted",
      feature: "Settings",
    }),
  trackSettingsInvoicesTermCreate: ({ days, name }) =>
    tracker({
      event: "Invoice term created",
      feature: "Settings",
      days,
      name,
    }),
  trackSettingsInvoicesTermDelete: () =>
    tracker({
      event: "Invoice term deleted",
      feature: "Settings",
    }),
  trackSettingsInvoicesExpenseCategoryCreate: ({ name }) =>
    tracker({
      event: "Invoice expense category created",
      feature: "Settings",
      name,
    }),
  trackSettingsInvoicesExpenseCategoryDelete: () =>
    tracker({
      event: "Invoice expense category deleted",
      feature: "Settings",
    }),
  trackSettingsPermissionsEdit: () =>
    tracker({
      event: "Permissions edited",
      feature: "Settings",
    }),
  trackSettingsPermissionsCreate: () =>
    tracker({
      event: "Permissions created",
      feature: "Settings",
    }),
  trackSettingsPermissionsDelete: () =>
    tracker({
      event: "Permissions deleted",
      feature: "Settings",
    }),
  trackSettingsPermissionsDuplicate: () =>
    tracker({
      event: "Permissions duplicated",
      feature: "Settings",
    }),
  trackSettingsConsultantTemplateCreate: () =>
    tracker({
      event: "Consultant Template created",
      feature: "Settings",
    }),
  trackSettingsConsultantTemplateDelete: () =>
    tracker({
      event: "Consultant Template deleted",
      feature: "Settings",
    }),
};

const signupEvents = {
  trackSignupSubmit: () =>
    tracker({
      event: "Sign up submitted",
      feature: "Sign up",
    }),
};

const onboardingEvents = {
  trackOnboardingChecklistButtonClicked: () =>
    tracker({
      event: "Onboarding Checklist Button Clicked",
    }),
};

const navigationEvents = {
  trackHelpLinkClicked: () =>
    tracker({
      event: "Help link clicked",
      feature: "Navigation",
    }),
  trackReportsLinkClicked: () =>
    tracker({
      event: "Reports link clicked",
      feature: "Navigation",
    }),
  trackDemoOrganizationClicked: () =>
    tracker({
      event: "Demo organization clicked",
      feature: "Navigation",
    }),
  trackPrimaryOrganizationClicked: () => {
    tracker({
      event: "Primary organization clicked",
      feature: "Navigation",
    });
  },
  trackWhatsNewClicked: ({ hadNotification }) => {
    tracker({
      event: "What's New Clicked",
      feature: "Navigation",
      hadNotification,
    });
  },
  trackSearchResultClicked: ({ type, id }) => {
    tracker({
      event: "Universal Search Result Clicked",
      feature: "Navigation",
      type,
      id,
    });
  },
  trackSearchOpened: ({ withKeyboardShortcut = false }) => {
    tracker({
      event: "Universal Search Bar Opened",
      feature: "Navigation",
      withKeyboardShortcut,
    });
  },
};

const subscriptionEvents = {
  trackPlanModalDisplay: ({ upgradeModalTitle, pathString }) =>
    tracker({
      event: "Plan Modal - Displayed",
      modal_title: upgradeModalTitle,
      path: pathString,
    }),
  trackPlanModalClick: ({
    upgradeModalTitle,
    pathString,
    columnTitle,
    buttonText,
    planIntervalSetting,
  }) =>
    tracker({
      event: "Plan Modal CTA - Clicked",
      modal_title: upgradeModalTitle,
      path: pathString,
      column_title: columnTitle,
      button_text: buttonText,
      plan_interval_setting: planIntervalSetting,
    }),
  trackModalGrowUpgradeClick: ({ pathString }) =>
    tracker({
      event: "Upgrade to Grow Button - Clicked",
      path: pathString,
    }),
};

const eventTypes = {
  ...navigationEvents,
  ...onboardingEvents,
  ...projectsEvents,
  ...reportsEvents,
  ...resourceEvents,
  ...settingsEvents,
  ...signupEvents,
  ...teamEvents,
  ...timesheetsEvents,
  ...subscriptionEvents,
};

export const eventTracker = (event, data) => {
  try {
    eventTypes[event](data);
  } catch (err) {
    console.warn("Tracking error", err);
  }
};

export default eventTracker;
