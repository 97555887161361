import * as React from "react";
import type {
  ActionMeta,
  IndicatorProps,
  OptionProps,
  OptionsType,
  StylesConfig,
} from "react-select";
import ReactSelect, {
  components,
  GroupTypeBase,
  OptionTypeBase,
} from "react-select";
import ReactAsyncSelect from "react-select/async";
import ReactSelectCreateable from "react-select/creatable";
import { formatGroupLabel } from "react-select/src/builtins";
import { SelectComponents } from "react-select/src/components";
import { MenuPortalProps } from "react-select/src/components/Menu";
import { NamedProps } from "react-select/src/Select";

import { createComponent, styled, useModalTrap } from "@plan/core";
import { IconChevronDown } from "@plan/icons";

import { useField } from "../Field";
import {
  InputTransitionalProps,
  InputWrapper,
  Label,
  useGetMemoizedHtmlId,
} from "../Input";

import { AsyncMultiSelect } from "./AsyncMultiSelect";
import { MultiSelect } from "./MultiSelect";
import { NestedSelect } from "./NestedSelect";

import omit from "lodash/omit";

const component = createComponent();

export enum SelectTypesList {
  creatable = "creatable",
  searchable = "searchable",
  multiselect = "multiselect",
  async = "async",
  asyncMulti = "asyncMulti",
  nestedSelect = "nestedSelect",
}

export type SelectOptionTypeBase = OptionTypeBase;

export type SelectOptionsProps<
  T extends OptionTypeBase,
  M extends boolean
> = OptionProps<T, M>;

export type SelectOptionType = readonly (
  | OptionTypeBase
  | GroupTypeBase<OptionTypeBase>
)[] &
  OptionTypeBase[];

export type BaseSelectProps<
  T extends OptionTypeBase = OptionTypeBase,
  M extends boolean = true | false
> = NamedProps<T, M> & {
  children?: React.ReactNode;
  multiSelectLabel?: React.ReactNode;
  multiSelectWithChips?: boolean;
  optionCreate?: {
    label: React.ReactNode;
    onClick: () => void;
  };
  loadOptions?: (inputValue: string) => Promise<OptionTypeBase[]>;
  options?: T[];
  type?:
    | "creatable"
    | "multiselect"
    | "searchable"
    | "async"
    | "asyncMulti"
    | "nestedSelect";
  // This prop is specifically useful to control the async select.
  // Providing an option array to this prop will populate the initial set of options used when opening the select,
  // at which point the remote load only occurs when filtering the options (typing in the control).
  // Providing the prop by itself (or with 'true') tells the control to immediately fire the remote request, described by your loadOptions,
  // to get those initial values for the Select.
  // Docs: https://react-select.com/async
  defaultOptions?: boolean | T[];
  styles?: StylesConfig<T, M>;
  cacheOptions?: boolean;
};

type SelectComponentProps<
  // FIXME: drop on first refactor
  // eslint-disable-next-line
  C extends keyof SelectComponents<any, any>,
  T = SelectOptionType,
  M extends boolean = true | false
  // FIXME: drop on first refactor
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
> = React.ComponentProps<SelectComponents<T, M>[C]>;
export type SelectSingleValueProps<
  T = OptionTypeBase,
  M extends boolean = true | false
> = SelectComponentProps<"SingleValue", T, M>;

const defaultSelectConfig = {
  classNamePrefix: "plan-select",
};

// Configure react select
const BaseSelect = ({ type, ...props }: BaseSelectProps) => {
  if (type === "searchable")
    return <ReactAsyncSelect {...defaultSelectConfig} {...props} />;

  if (type === "creatable")
    return <ReactSelectCreateable {...defaultSelectConfig} {...props} />;

  if (type === "multiselect")
    return <MultiSelect {...defaultSelectConfig} {...props} />;

  if (type === "async")
    return (
      <ReactAsyncSelect
        {...defaultSelectConfig}
        // These 2 props are necessary to make Async work.
        // If you need to call async function when open select, then you must pass a default option array
        cacheOptions={props.cacheOptions}
        defaultOptions
        loadOptions={props.loadOptions}
        {...props}
      />
    );

  if (type === "asyncMulti")
    return <AsyncMultiSelect {...defaultSelectConfig} {...props} />;

  if (type === "nestedSelect")
    // FIXME: drop on first refactor
    // eslint-disable-next-line
    // @ts-ignore
    return <NestedSelect {...defaultSelectConfig} {...props} />;

  return <ReactSelect {...defaultSelectConfig} {...props} />;
};

// Wrap it in stitches
// See the storybook docs for HTML strucure
export const StyledSelect = styled(BaseSelect, {
  ...component,
  $$defaultBorder: "1px solid $colors$-neutral10",
  $$hoverBorder: "1px solid $colors$-neutral10",
  $$transparentBorder: "1px solid $colors$transparent",
  $$errorBorder: "1px solid $colors$danger10",
  $$selectIconColor: "$colors$neutral10",
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "baseline",
  background: "$colors$white",
  width: "100%",
  fontSize: "$fontSizes$lg",
  transition: "all 150ms $transitions$cb",
  // TODO: AutoLayout Quirks (MGX-342)
  //       1. Investigate how to avoid bespoke flex-basis
  //          As we use `overflow: hidden` to ensure content is kept within
  //          `border-radius`, this will cause content collapse in `AutoLayout`
  //          (due to default `flex-basis` being set to `0` for equal spacing)
  "&&": {
    "&&": {
      flexBasis: "auto",
    },
  },
  "& .plan-select__single-value": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&--is-disabled": {
      color: "$colors$neutral10",
    },
  },
  "& .plan-select__control": {
    width: "100%",
    height: "$sizes$xl",
    padding: "0",
    background: "transparent",
    borderRadius: "$radii$default",
    fontFamily: "$sans",
    fontSize: "$fontSizes$lg",
    lineHeight: 1,
    outline: "none",
    color: "$colors$neutral10",
    "&--is-disabled": {
      $$selectIconColor: "$colors$-neutral10",
      background: "$colors$-neutral40",
      color: "$colors$neutral10",
    },
    "&--is-focused": {
      boxShadow: "$focus",
      borderColor: "$colors$neutral40",
    },
    "&:hover, &--is-focused, &--is-focused:hover": {
      border: "$$hoverBorder",
      borderColor: "$colors$neutral40",
    },
    "&--is-disabled:hover": {
      border: "$$defaultBorder",
    },
  },
  "& .plan-select__placeholder": {
    color: "$colors$neutral10",
  },
  "& .plan-select__value-container": {
    width: "100%",
    height: "100%",
  },

  // Controls
  // Hide the vertical line
  "& .plan-select__indicator": {
    padding: "0.1rem",
  },
  "& .plan-select__indicator-separator": {
    display: "none",
  },
  "& .plan-select__dropdown-indicator, & .plan-select__clear-indicator": {
    alignSelf: "center",
    "&&": {
      color: "$$selectIconColor",
    },
  },
  // Menu
  "& .plan-select__menu": {
    whiteSpace: "nowrap",
    width: "auto",
    minWidth: "100%",
  },

  // Options
  "& .plan-select__option": {
    cursor: "pointer",
    contentVisibility: "auto",
    marginBottom: "1px",
  },
  "& .plan-select__option:hover, & .plan-select__option--is-focused": {
    background: "$colors$-brand40",
  },
  "& .plan-select__option--is-selected": {
    background: "$colors$brand",
    color: "$colors$white",
  },
  "& .plan-select__option--is-disabled": {
    cursor: "not-allowed",
    color: "$colors$neutral",
  },
  "& .plan-select__option--is-disabled:hover": {
    background: "none",
  },
  "& .plan-select__option--is-selected:hover, & .plan-select__option--is-selected.plan-select__option--is-focused":
    {
      background: "$colors$brand10",
      color: "$colors$white",
    },
  variants: {
    ...component.variants,
    wrap: {
      true: {
        "& .plan-select__control": {
          flexWrap: "wrap",
          height: "auto",
        },

        "& .plan-select__value-container--is-multi": {
          flexWrap: "wrap",
          height: "100%",
        },
      },
      false: {
        "& .plan-select__value-container--is-multi": {
          flexWrap: "nowrap",
        },
      },
    },
    width: {
      fluid: {
        width: "100%",
      },
      fixed: {
        maxWidth: "$space$44",
      },
    },
    border: {
      default: {
        "& .plan-select__control": {
          border: "$$defaultBorder",
        },
      },
      transparent: {
        "& .plan-select__control": {
          border: "$$transparentBorder",
        },
      },
      error: {
        "&&": {
          "& .plan-select__control": {
            border: "$$errorBorder",
          },
        },
      },
    },
  },
  defaultVariants: {
    ...component.defaultVariants,
    resizingX: "fill-container",
    border: "default",
    wrap: false,
  },
});

const StyledGroupLabel = styled("span", {
  fontWeight: "$semibold",
  fontSize: "$lg",
  textTransform: "capitalize",
  color: "$neutral40",
});

const DropdownIndicatorIcon = styled(IconChevronDown, {
  marginRight: "$space$1",
});

export function DropdownIndicator<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(props: IndicatorProps<OptionType, IsMulti, GroupType>): React.ReactElement {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorIcon label="dropdown" />
    </components.DropdownIndicator>
  );
}

const MenuPortal = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  props: MenuPortalProps<OptionType, IsMulti, GroupType>
): React.ReactElement => {
  return (
    <components.MenuPortal {...props} className={StyledSelect.className} />
  );
};

type SelectProps = React.ComponentProps<typeof StyledSelect> &
  BaseSelectProps &
  InputTransitionalProps;

const groupLabel: formatGroupLabel<
  OptionTypeBase,
  GroupTypeBase<OptionTypeBase>
> = (data) => (
  <div>
    <StyledGroupLabel>{data.label}</StyledGroupLabel>
  </div>
);

export const Select = ({
  id,
  border,
  isDisabled,
  showLabel = true,
  label,
  withinField,
  options = [],
  optionCreate,
  styles,
  components,
  ...props
}: SelectProps) => {
  const { getInputProps } = useField("fieldset");
  const inputProps = getInputProps({ id, disabled: isDisabled });

  const [, setModalTrap] = useModalTrap();
  const memoizedId = useGetMemoizedHtmlId(label || "within-field");

  const handleMenuOpen = () => {
    setModalTrap(true);
    if (typeof props?.onMenuOpen === "function") props.onMenuOpen();
  };

  const handleMenuClose = () => {
    setModalTrap(false);
    if (typeof props?.onMenuClose === "function") props.onMenuClose();
  };

  const handleOnChange = (
    option: OptionTypeBase | OptionsType<OptionTypeBase> | null,
    action: ActionMeta<OptionTypeBase>
  ) => {
    const createOptionSelected = Array.isArray(option)
      ? option.find((o) => o.value === CREATE_OPTION_VALUE) // if it's an array, check whether any of the selected values is the create value
      : // eslint-disable-next-line
        // @ts-ignore
        option?.value === CREATE_OPTION_VALUE; // otherwise, check the single selected value

    createOptionSelected
      ? null
      : props.onChange && props.onChange(option, action);
  };

  const CREATE_OPTION_VALUE = "option_create";

  const optionsWithCreate = optionCreate
    ? [
        ...options,
        {
          label: (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div onClick={optionCreate.onClick}>
              {optionCreate.label || "+ Add new"}
            </div>
          ),
          value: CREATE_OPTION_VALUE,
        },
      ]
    : options;

  return withinField ? (
    <StyledSelect
      id={inputProps.id}
      instanceId={inputProps.id}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      onChange={handleOnChange}
      border={border || inputProps.invalid ? "error" : "default"}
      isDisabled={inputProps.disabled}
      formatGroupLabel={groupLabel}
      components={{
        // @ts-ignore –TODO: fix types here: https://react-select.com/components
        DropdownIndicator,
        MenuPortal,
        ...components,
      }}
      options={optionsWithCreate}
      wrap={props.multiSelectWithChips}
      {...props}
    />
  ) : (
    <InputWrapper>
      {label && (
        <Label htmlFor={memoizedId} showLabel={showLabel}>
          {label}
        </Label>
      )}
      <StyledSelect
        id={memoizedId}
        instanceId={inputProps.id}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        onChange={handleOnChange}
        border={border}
        // @ts-ignore –TODO: fix types here: https://react-select.com/components
        components={{ DropdownIndicator, MenuPortal, ...components }}
        isDisabled={isDisabled}
        formatGroupLabel={groupLabel}
        options={optionsWithCreate}
        wrap={props.multiSelectWithChips}
        styles={{
          ...styles,
          option: (provided, state) => {
            const propsStyles = styles?.option
              ? styles.option(provided, state)
              : null;

            if (state.data.value === CREATE_OPTION_VALUE) {
              return {
                ...provided,
                ...{
                  color: "var(--plan--colors-brand) !important",
                  cursor: "pointer !important",
                  background: "white !important",
                  "&:hover": {
                    backgroundColor: "var(--plan--colors--brand40) !important",
                  },
                },
                ...propsStyles,
              };
            } else return { ...provided, ...propsStyles };
          },
        }}
        {...omit(props, "onChange")}
      />
    </InputWrapper>
  );
};
