
const digest = '70fa5aa7fbc6f02a02d7af28dc44b160242ef3d0444685c4e73c8d4cedeb91c3';
const css = `._loadingSkeleton_16yfp_1 {
  position: relative;

  background: var(--background);
  border-radius: var(--border-radius);
  overflow: hidden;
}

._animated_16yfp_9:after {
  position: absolute;

  animation: _loading_16yfp_1 0.75s infinite;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(0, 0, 0, 0.05)),
    to(transparent)
  );
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.05),
    transparent
  );
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  transform: translateX(-100%);
  width: 125%;
}

@keyframes _loading_16yfp_1 {
  100% {
    transform: translateX(100%);
  }
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"loadingSkeleton":"_loadingSkeleton_16yfp_1","animated":"_animated_16yfp_9","loading":"_loading_16yfp_1"};
export { css, digest };
  