
const digest = 'e886bd163bb497a092d8511d37d4c6df8403269f16ce1d84d8f356a9993e6dc0';
const css = `._negative_phxb9_1 {
  color: var(--color-red);
}

._hoursLabel_phxb9_5 {
  font-size: 0.5rem;
  margin-left: 2px;
  line-height: 1;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"negative":"_negative_phxb9_1","hoursLabel":"_hoursLabel_phxb9_5"};
export { css, digest };
  