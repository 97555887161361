import { useEffect, useRef } from "react";
import {
  Row,
  TableToggleAllRowsSelectedProps,
  TableToggleCommonProps,
} from "react-table";

import { styled } from "@plan/core";
import { IconDone, IconRemove } from "@plan/icons";

import { Text } from "../../Text";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../Tooltip";
export const StyledIndeterminateCheckbox = styled("input", {
  "-webkit-appearance": "none",
  "-moz-appearance": "none",
  cursor: "pointer",
  height: "$sizes$xs",
  width: "$sizes$xs",
  border: "1px solid $colors$neutral",
  borderRadius: "$radii$default",
  backgroundColor: "none",
  zIndex: "1",
  position: "relative",
  "&:hover": {
    backgroundColor: "$colors$-brand30",
    borderColor: "$colors$-brand30",
  },
  "&:after": {
    backgroundColor: "$colors$-brand30",
    border: "1px solid $colors$-brand30",
  },
  "&:focus": {
    ring: "$colors$-brand30",
  },
  "&:checked": {
    borderColor: "$colors$brand",
    "&:hover": {
      background: "none",
    },
    "&:after": {
      background: "none",
    },
  },
  "&:disabled": {
    background: "$colors$-neutral30",
    borderColor: "$colors$-neutral30",
  },
});

export const IndeterminateCheckbox = ({
  indeterminate,
  allowDeselectAll,
  selectedRows,
  rows,
  toggleAllRowsSelected,
  ...rest
}: Partial<TableToggleAllRowsSelectedProps> & {
  disabled?: boolean;
  allowDeselectAll?: boolean;
  rows?: Row[];
  selectedRows?: Row[];
  toggleAllRowsSelected?: (value: boolean) => void;
}) => {
  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  const ref = useRef<HTMLInputElement>(null!);

  const showDeselect = allowDeselectAll && !!selectedRows?.length;

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate =
        showDeselect || (!rest.checked && indeterminate);
    }
  }, [ref, indeterminate, rest.checked, showDeselect]);

  const showIcon = showDeselect || rest.checked ? "block" : "none";

  const checkbox =
    rest.title === "Toggle All Rows Selected" ? (
      <IconRemove
        label="Invoices"
        css={{
          backgroundColor: "$brand",
          border: "1px solid $colors$-brand30",
          borderRadius: "$radii$default",
          color: "$white",
          position: "absolute",
          width: "16px",
          height: "16px",
          top: "3px",
          left: "4px",
          zIndex: "0",
          display: showIcon,
        }}
      />
    ) : (
      <IconDone
        label="Invoices"
        css={{
          backgroundColor: "$brand",
          border: "1px solid $colors$-brand30",
          borderRadius: "$radii$default",
          color: "$white",
          position: "absolute",
          top: "3px",
          left: "4px",
          zIndex: "0",
          display: showIcon,
        }}
      />
    );

  const deselectAll = () =>
    toggleAllRowsSelected && toggleAllRowsSelected(!rows?.length);

  const checkboxProps = {
    ...rest,
    ...(showDeselect ? { onChange: deselectAll } : {}),
  };

  return (
    <div style={{ position: "relative" }}>
      <StyledIndeterminateCheckbox
        type="checkbox"
        ref={ref}
        {...checkboxProps}
      />
      {checkbox}
    </div>
  );
};

interface RowData {
  [key: string]: unknown;
  isDisabled?: boolean;
}

type CheckboxColumnProps = {
  allowDeselectAll: boolean;
};

interface CheckboxHeaderProps {
  getToggleAllRowsSelectedProps: () => Partial<TableToggleAllRowsSelectedProps>;
  toggleAllRowsSelected: () => void;
  selectedFlatRows: Row[];
  rows: Row[];
}

export const checkboxColumn = ({ allowDeselectAll }: CheckboxColumnProps) => ({
  id: "selection",
  disableSortBy: true,
  preventToggle: true,
  Header: ({
    getToggleAllRowsSelectedProps,
    selectedFlatRows,
    rows,
    toggleAllRowsSelected,
  }: CheckboxHeaderProps) => {
    const allRowsDisabled = rows.every(
      (row) => (row.original as RowData).isDisabled === true
    );

    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <IndeterminateCheckbox
            {...getToggleAllRowsSelectedProps()}
            rows={rows}
            toggleAllRowsSelected={toggleAllRowsSelected}
            allowDeselectAll={allowDeselectAll}
            selectedRows={selectedFlatRows}
            disabled={allRowsDisabled}
          />
        </TooltipTrigger>
        <TooltipContent
          side="top"
          css={{ maxWidth: "$space$60", textAlign: "center" }}
        >
          Select all on this page
        </TooltipContent>
      </Tooltip>
    );
  },
  Cell: ({
    row,
  }: {
    row: {
      getToggleRowSelectedProps: () => TableToggleCommonProps;
      original: RowData;
    };
  }) => {
    const isDisabled = row.original.isDisabled || false;
    const disabledTooltip = row.original.disabledTooltip || undefined;

    if (disabledTooltip) {
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span>
              <IndeterminateCheckbox disabled={true} />
            </span>
          </TooltipTrigger>
          <TooltipContent side="bottom" css={{ maxWidth: "$space$60" }}>
            <Text>{disabledTooltip as string}</Text>
          </TooltipContent>
        </Tooltip>
      );
    }

    return (
      <IndeterminateCheckbox
        disabled={isDisabled}
        {...row.getToggleRowSelectedProps()}
      />
    );
  },
});
