
const digest = 'c6a6cae13423ed248053451d0e364dd4244a8c024cc6c02860817ce5ceadd240';
const css = `._tableWrapper_cu9cg_1 {
  margin: 1.5rem;
  background-color: var(--color-white);
  border: 1px solid var(--color--neutral-20);
  border-radius: 4px;
}

._tableBar_cu9cg_8 {
  height: 4rem;
  background-color: white;
  border-bottom: 1px solid var(--color--neutral-20);
}

._groupBy_cu9cg_14 {
  width: 12rem;
  padding: 0.75rem;
}

._groupByHolder_cu9cg_19 {
  color: var(--color-neutral-10);
  padding-right: 0.25rem;
}

._columnHeaderWrapper_cu9cg_24 {
  display: flex;
  height: 3rem;
  margin-left: 1.5rem;
  margin-right: 3.5rem;
}

._columnTitleWrapper_cu9cg_31 {
  width: 100%;
  line-height: 3rem;
  display: flex;
  justify-content: space-between;
}

._columnTitle_cu9cg_31 {
  color: var(--color-neutral-10);
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  flex: 1 1 0;
  text-align: right;
}
._columnTitle_cu9cg_31:first-child {
  text-align: left;
}
._columnTitle_cu9cg_31:nth-child(2) {
  margin-left: -12.5%;
}

._expandableRow_cu9cg_53 {
  display: flex;
  width: 100%;
  padding: 1rem 0;
  padding-left: 1.5rem;
  border-top: 1px solid var(--color--neutral-20);
}

._expandableRow_cu9cg_53:hover {
  background-color: var(--color-legacy-gray-1);
  transition: background-color ease-in 200ms;
  cursor: pointer;
}

._isOpen_cu9cg_67:hover {
  background-color: var(--color-white);
}

._table_cu9cg_1 {
  margin: 1.5rem;
  background-color: var(--color-white);
  border: 1px solid var(--color--neutral-20);
  border-radius: 4px;
  margin-top: 0;
}

._rowValueWrapper_cu9cg_79 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

._rowValue_cu9cg_79 {
  font-weight: 600;
  line-height: 1rem;
  flex: 1 1 0;
  text-align: right;
}
._rowValue_cu9cg_79:nth-child(2) {
  margin-left: -12.5%;
}

._rowHeader_cu9cg_95 {
  text-align: left;
}

._toggleWrapper_cu9cg_99 {
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

._toggleRow_cu9cg_106 {
  position: absolute;
  width: 0.75rem;
  height: 0.5rem;
  transition: transform 300ms;
}

._isOpen_cu9cg_67 ._toggleRow_cu9cg_106 {
  transform: rotate(180deg);
}

._toggleRow_cu9cg_106:before,
._toggleRow_cu9cg_106:after {
  content: "";
  position: absolute;
  width: 0.125em;
  height: 0.5em;
  top: 0;
  border-radius: 0.125em;
  background-color: var(--color-neutral-10);
}

._toggleRow_cu9cg_106:before {
  left: 0.1875em;
  transform: rotate(-45deg);
}
._toggleRow_cu9cg_106:after {
  left: 0.4375em;
  transform: rotate(45deg);
}

._entries_cu9cg_137 .page-module,
._entries_cu9cg_137 .page-module__scrollable {
  border: none;
  margin-bottom: 0;
}

._entries_cu9cg_137 .page-table__header {
  z-index: 0;
}

._entries_cu9cg_137 .page-table__row:last-child .page-table__cell {
  /* border-bottom: none; */
}

._entriesTotal_cu9cg_151 {
  color: var(--color-neutral-10);
  font-size: 1rem;
  font-weight: 600;
  padding-left: 0.5rem;
  line-height: 1rem;
}

._totalGrouped_cu9cg_159 {
  display: flex;
  justify-content: space-between;
  margin-left: calc(75% - 6rem);
  padding: 0.5rem 2.5rem;
}

._totalLabel_cu9cg_166 {
  padding-left: 0.25rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"tableWrapper":"_tableWrapper_cu9cg_1","tableBar":"_tableBar_cu9cg_8","groupBy":"_groupBy_cu9cg_14","groupByHolder":"_groupByHolder_cu9cg_19","columnHeaderWrapper":"_columnHeaderWrapper_cu9cg_24","columnTitleWrapper":"_columnTitleWrapper_cu9cg_31","columnTitle":"_columnTitle_cu9cg_31","expandableRow":"_expandableRow_cu9cg_53","isOpen":"_isOpen_cu9cg_67","table":"_table_cu9cg_1","rowValueWrapper":"_rowValueWrapper_cu9cg_79","rowValue":"_rowValue_cu9cg_79","rowHeader":"_rowHeader_cu9cg_95","toggleWrapper":"_toggleWrapper_cu9cg_99","toggleRow":"_toggleRow_cu9cg_106","entries":"_entries_cu9cg_137","entriesTotal":"_entriesTotal_cu9cg_151","totalGrouped":"_totalGrouped_cu9cg_159","totalLabel":"_totalLabel_cu9cg_166"};
export { css, digest };
  